<template>
  <div>
    <Cover></Cover>
    <router-view style="min-height: 747px"></router-view>
  </div>
</template>

<script>
import Cover from "../../layouts/Cover";
import eventBus from "vue3-eventbus";
import state from "../../store/state";
import article from "../../api/article";
export default {
  name: "Case",
  data() {
    return {
      cover: {
        img: "https://oss.pigwan.net/wapi/upload/images/2021/12/case.jpg",
        title: "项目案例",
        description: "公司项目案例"
      },
      cate_id:14
    };
  },
  components: {
    Cover
  },
  mounted() {
    eventBus.emit("cover", this.cover);
    this.getArticleCate();
  },
  methods:{
    getArticleCate() {
      let website_id = state.websiteSet.website.id;
      article.getArticleCate(website_id,this.cate_id)
        .then((res) => {
          if (res.code === 0) {
            eventBus.emit("case_cate", res.data);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>

</style>